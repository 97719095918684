@import "../scss/_variables.scss";
/* the old colors, but still in use somewhere...*/
.bg-ifx-navy {
  background-color: $ifx-navy;
}

.bg-ifx-navy-light {
  background-color: $ifx-navy-light;
}

.bg-ifx-blue {
  background-color: $ifx-blue;
}

.bg-ifx-blue-bright {
  background-color: $ifx-blue-bright;
}

.bg-ifx-teal {
  background-color: $ifx-teal;
}

.bg-ifx-teal2 {
  background-color: $ifx-teal2;
}

.bg-ifx-orange-muted {
  background-color: $ifx-orange-muted;
}

.bg-ifx-gold {
  background-color: $ifx-gold;
}

.bg-ifx-gold-dark {
  background-color: $ifx-gold-dark;
}

.bg-ifx-gold-light {
  background-color: $ifx-gold-light;
}

.bg-ifx-gold-extralight {
  background-color: $ifx-gold-extralight;
}

.bg-ifx-orange {
  background-color: $ifx-orange;
}

.bg-ifx-green {
  background-color: $ifx-green;
}

.bg-ifx-grey-light {
  background-color: $ifx-grey-light;
}

.bg-ifx-grey-extralight {
  background-color: $ifx-grey-extralight;
}

.bg-ifx-grey-dark {
  background-color: $ifx-grey-dark;
}
