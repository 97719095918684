/* Variable overrides */
/* these are the old colors from long long ago */
$ifx-blue-bright: #00ffcc;
$ifx-blue: #3ad7ff;
$ifx-gold-dark: #e6b800;
$ifx-gold-extralight: #fff3c2;
$ifx-gold-light: #fbe691;
$ifx-gold: #ffcc00;
$ifx-green: rgba(8, 100, 17, 1);
$ifx-grey-dark: #595959;
$ifx-grey-extralight: #f0f0f0;
$ifx-grey-light: #c0c0c0;
$ifx-navy-light: #356ea9;
$ifx-navy: #003366;
$ifx-orange-muted: #c67b46;
$ifx-orange: #fd8e2e;
$ifx-teal2: #73c2a5;
$ifx-teal: #027e9c;
